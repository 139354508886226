import React, { Component } from 'react';
import { ButtonGroup, Button } from '../reusable/Button';
import { Page, PageContent, PageHeader } from "../reusable/Page";
import { Table } from '../reusable/Table';
import { ActiveFormIcon, RefreshIcon } from '../reusable/Icons';
import { formatDate } from '../../utils/format';

const formSort = (f1,f2) => {
    const n1 = ((f1.projectNumber === f2.projectNumber) ? f1.roomNumber : f1.projectNumber).toUpperCase();
    const n2 = ((f1.projectNumber === f2.projectNumber) ? f2.roomNumber : f2.projectNumber).toUpperCase();
    if (n1 < n2) {
        return -1;
    }
    if (n1 > n2) {
        return 1;
    }
    return 0;
};

class SelfInspectionActiveList extends Component {

    open(id) {
        this.props.open(id);
    }

    delete(id) {
        this.props.actions.deleteActive(id);
    }

    refresh() {
        this.props.actions.loadActive();
    }

    render() {
        return (<Page>
            <PageHeader>
                <h1><ActiveFormIcon/> Påbörjade egenkontroller</h1>
            </PageHeader>
            <PageContent>
                <ButtonGroup>
                    <Button onClick={this.refresh.bind(this)}><RefreshIcon/></Button>
                </ButtonGroup>
                <Table>
                    <thead>
                    <tr>
                        <th>Projectnummer</th>
                        <th>Rum</th>
                        <th>Påbörjad</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.props.activeList.sort(formSort).map(form => <tr key={form.id}>
                        <td>{form.projectNumber}</td>
                        <td>{form.roomNumber}</td>
                        <td>{formatDate(form.createdAt)}</td>
                        <td>
                            <ButtonGroup no-margin right>
                                <Button onClick={this.open.bind(this, form.id)}>Öppna</Button>
                                <Button onClick={this.delete.bind(this, form.id)} type="danger">Ta bort</Button>
                            </ButtonGroup>
                        </td>
                    </tr>)}
                    </tbody>

                </Table>
            </PageContent>
        </Page>);
    }

}

export default SelfInspectionActiveList;