import { LOAD_PAGE, TOGGLE_MENU } from '../actionTypes';

export default function(navigation = { visible: false }, action) {

    switch(action.type) {
        case LOAD_PAGE:
            return Object.assign({}, navigation, { page: action.page, visible: false });
        case TOGGLE_MENU:
            return Object.assign({}, navigation, { visible: action.visible });
        default:
            return navigation;
    }

};