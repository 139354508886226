import React, { Component, PropTypes } from 'react';
import  ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Page, PageContent, PageHeader } from "../reusable/Page";
import { ButtonGroup, Button } from '../reusable/Button';
import { SubmittedFormIcon, PrintIcon } from '../reusable/Icons';
import apiHost from '../../utils/apiUrl';


const PdfWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 160%
`;

const PdfFrame = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border:none;
`;

class SelfInspectionSubmitted extends Component {

    print() {
        const iframe = ReactDOM.findDOMNode(this.iframe);
        iframe.contentWindow.print();
    }

    close() {
        this.props.close();
    }

    render() {
        return (<Page>
            <PageHeader>
                <h1><SubmittedFormIcon/> Inskickad egenkontroll</h1>
            </PageHeader>
            <PageContent>

                <ButtonGroup>
                    <Button onClick={this.close.bind(this)} type="cancel">Stäng</Button>
                    <Button onClick={this.print.bind(this)}><PrintIcon /> Skriv ut</Button>
                </ButtonGroup>

                { (!this.props.currentSubmitted || !this.props.currentSubmitted.id) ? null :
                    (<PdfWrapper>
                        <PdfFrame
                            ref={iframe => { this.iframe = iframe; }}
                            frameborder="0"
                            src={`${apiHost}/self-inspection/submitted/${this.props.currentSubmitted.id}/pdf`}
                        />
                    </PdfWrapper>)
                }
            </PageContent>
        </Page>);
    }

}

export default SelfInspectionSubmitted;