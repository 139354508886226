import React, { Component } from 'react';
import Settings from './pages/Settings';
import ProjectList from './pages/ProjectList';
import SelfInspectionActiveList from './pages/SelfInspectionActiveList';
import SelfInspectionActiveEdit from './pages/SelfInspectionActiveEdit';
import SelfInspectionSubmittedList from './pages/SelfInspectionSubmittedList';
import SelfInspectionSubmitted from './pages/SelfInspectionSubmitted';
import Users from './pages/Users';

class Router extends Component {

    render() {

        switch(this.props.navigation.page || 'settings') {
            case 'settings':
                return (<Settings settings={this.props.settings} user={this.props.user} actions={this.props.actions.settings}/>);
            case 'project-list':
                return (<ProjectList
                    project={this.props.project}
                    actions={this.props.actions.project}
                    createSelfInspection={this.props.actions.selfInspection.initNew}
                    user={this.props.user}
                />);
            case 'self-inspection/active-list':
                return (<SelfInspectionActiveList
                    activeList={this.props.selfInspection.activeList}
                    open={this.props.actions.navigation.openActiveSelfInspection}
                    user={this.props.user}
                    actions={this.props.actions.selfInspection}
                />);
            case 'self-inspection/active':
                return (<SelfInspectionActiveEdit
                    current={this.props.selfInspection.currentActive}
                    user={this.props.user}
                    close={this.props.actions.navigation.openActiveSelfInspectionList}
                    actions={this.props.actions.selfInspection}
                />);
            case 'self-inspection/submitted-list':
                return (<SelfInspectionSubmittedList
                    allSubmitted={this.props.selfInspection.allSubmitted}
                    user={this.props.user}
                    actions={this.props.actions.selfInspection}
                    open={this.props.actions.navigation.openSubmittedSelfInspection}
                />);
            case 'self-inspection/submitted':
                return (<SelfInspectionSubmitted
                    currentSubmitted={this.props.selfInspection.currentSubmitted}
                    close={this.props.actions.navigation.openSubmittedSelfInspectionList}
                />);
            case 'users':
                return (<Users
                    users={this.props.users}
                    user={this.props.user}
                    newUser={this.props.newUser}
                    actions={this.props.actions.user}
                />);
            default:
                return (<div>Sidan kunde inte hittas</div>);
        }

    }

}

export default Router;