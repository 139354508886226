import React, { Component } from 'react';
import { Button, ButtonGroup } from '../reusable/Button';
import { FormGroup } from '../reusable/Form';
import { Page, PageContent, PageHeader } from "../reusable/Page";
import { SettingsIcon } from '../reusable/Icons';

class Settings extends Component {

    update(field, event) {
        let updates = {};
        updates[field] = event.target.value;
        this.props.actions.addPendingUserUpdates(updates);
    }

    save() {
        this.props.actions.saveSettings(this.props.user.id, Object.assign({}, this.props.settings));
    }

    cancel() {
        this.props.actions.cancelPendingUpdates();
    } 

    render() {
        return (<Page>
            <PageHeader>
                <h1><SettingsIcon/> Inställningar</h1>
            </PageHeader>
            <PageContent>
                <FormGroup>
                    <label>E-postadress</label>
                    <input type="text" value={this.props.user.email} disabled="disabled" />
                </FormGroup>
                <FormGroup>
                    <label>Förnamn</label>
                    <input type="text" value={typeof(this.props.settings.userUpdates.firstName) === 'string' ? this.props.settings.userUpdates.firstName : this.props.user.firstName} onChange={this.update.bind(this, 'firstName')} />
                </FormGroup>
                <FormGroup>
                    <label>Efternamn</label>
                    <input type="text" value={typeof(this.props.settings.userUpdates.lastName) === 'string' ? this.props.settings.userUpdates.lastName : this.props.user.lastName} onChange={this.update.bind(this, 'lastName')} />
                </FormGroup>
                { typeof(this.props.settings.userUpdates.firstName) !== 'string' && typeof(this.props.settings.userUpdates.lastName) !== 'string' ? null :
                    <ButtonGroup>
                        <Button onClick={this.save.bind(this)}>Spara</Button>
                        <Button onClick={this.cancel.bind(this)} type={'cancel'}>Avbryt</Button>
                    </ButtonGroup>
                }
            </PageContent>
        </Page>);
}

}

export default Settings;