

function Color(data) {
    if (typeof data === 'string') {
        const hexString = `0x${data.substring(1)}`;
        this.r = (hexString>>16)&255;
        this.g = (hexString>>8)&255;
        this.b = hexString&255;
    } else {
        this.r = data.r;
        this.g = data.g;
        this.b = data.b;
    }

};

Color.prototype.toString = function() {
    return `rgb(${this.r},${this.g},${this.b})`;
};

Color.prototype.alpha = function(alpha) {
    return `rgba(${this.r},${this.g},${this.b},${alpha})`;
};

Color.prototype.getRgb = function() {
    return { r: this.r, g: this.g, b: this.b };
};

Color.prototype.mix = function(color, share) {
    const mixColorRgb = color.getRgb();
    return new Color({
        r: Math.floor(this.r * (1 - share) + mixColorRgb.r * share),
        g: Math.floor(this.g * (1 - share) + mixColorRgb.g * share),
        b: Math.floor(this.b * (1 - share) + mixColorRgb.b * share)
    });
};

/*
 export default {
 background: new Color('#242f3d'),
 foreground: new Color('#ffffff'),
 primary: new Color('#50b76f'),
 darkText: new Color('#5f6f79'),
 grayButton: new Color('#949494'),
 red: new Color('#e45757')
 };
*/


export default {
    background: new Color('#ffffff'),
    foreground: new Color('#343434'),
    primary: new Color('#f07207'),
    darkText: new Color('#5f6f79'),
    grayButton: new Color('#949494'),
    red: new Color('#e45757')
};