import React, { Component } from 'react';
import styled from 'styled-components';
import { ActiveFormIcon, ProjectIcon, SettingsIcon, SubmittedFormIcon, SignOutIcon, UsersIcon } from './reusable/Icons';

const transitionTime = 0.3;
const menuWidth = 230;

const Logo = styled.img`
    width: 156px;
    margin-top: 75px;
    margin-left: 35px;
`;

const MenuWrapper = styled.div`
    positon: fixed;
    top: 0;
    left: 0;
    * {
        color: white !important;
    }
`;

const MenuToggle = styled.div`
    position: fixed;
    top: 20px;
    left: 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    width: 40px;
    height: 40px;
    border-radius: 20px;
    font-size: 25px;
    text-align: center;
    line-height: 0;
    z-index: 501;
    cursor: pointer;
    i {
        margin-top: 7px;
    }
`;

const Menu = styled.div`
    position: fixed;
    top: 0;
    height: 100%;
    width: ${menuWidth}px;
    -webkit-transition: ${transitionTime}s;
    -moz-transition: ${transitionTime}s;
    -o-transition: ${transitionTime}s;
    transition: ${transitionTime}s;
    left: ${props => props.visible ? '0' : `-${menuWidth}px`};
    z-index: 500;
    background-color: black;
    a {
        color: white;
        cursor: pointer;
        display: block;
        margin: 20px 0 20px 45px;
        font-size: 20px;
    }
    p {
        color: white;
        display: block;
        margin: 20px 0 20px 45px;
        font-size: 20px;
        font-weight: 100;
    }
`;

class Navigation extends Component {

    toggle() {
        this.props.actions.toggleNavigation(!this.props.navigation.visible);
    }

    signOut() {
        this.props.signOut();
    }

    render() {
        return (<MenuWrapper>
                <MenuToggle>
                    <i className={`fa ${this.props.navigation.visible ? 'fa-times' : 'fa-bars'}`} aria-hidden="true" onClick={this.toggle.bind(this)}></i>
                </MenuToggle>
                <Menu visible={this.props.navigation.visible}>
                    <Logo src="/hms-logo.svg" />
                    { (!this.props.user || !this.props.user.firstName || !this.props.user.lastName) ? (<p>För- & efternamn <br/> måste anges</p>) : (<div>
                        <a onClick={this.props.actions.openSettings}><SettingsIcon/> Inställningar</a>
                        { (!this.props.user || !this.props.user.admin) ? null : (<div>
                            <a onClick={this.props.actions.openUsers}><UsersIcon/> Användare</a>
                        </div>) }
                        <a onClick={this.props.actions.openProjectList}><ProjectIcon/> Projekt</a>
                        <a onClick={this.props.actions.openActiveSelfInspectionList}><ActiveFormIcon/> Påbörjade</a>
                        <a onClick={this.props.actions.openSubmittedSelfInspectionList}><SubmittedFormIcon/> Inskickade</a>
                    </div>) }
                    <a onClick={this.signOut.bind(this)}><SignOutIcon/> Logga ut</a>
                </Menu>
            </MenuWrapper>);
    }

}

export default Navigation;