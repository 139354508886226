import React, { Component } from 'react';
import Overlay from '../reusable/Overlay';
import { FormGroup } from '../reusable/Form';
import { ButtonGroup, Button } from '../reusable/Button';

class UsersCreateNew extends Component {

    setEmail(event) {
        this.props.actions.setNewUserEmail(event.target.value);
    }

    cancel() {
        this.props.actions.cancelCreateUser();
    }

    save() {
        this.props.actions.createUser(this.props.newUser.email);
    }

    render() {
        if (!this.props.newUser) return null;
        return (
            <Overlay title="Ny användare">
                <div>
                    <FormGroup>
                        <label>E-postadress</label>
                        <input type="text" value={this.props.newUser.email} onChange={this.setEmail.bind(this)} />
                    </FormGroup>
                    <ButtonGroup>
                        <Button onClick={this.cancel.bind(this)} type="cancel">Avbryt</Button>
                        <Button onClick={this.save.bind(this)}>Spara</Button>
                    </ButtonGroup>
                </div>
            </Overlay>);
    }

}

export default UsersCreateNew;