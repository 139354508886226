import React, { Component, PropTypes } from 'react';

export class SettingsIcon extends Component {
    render() {
        return (<i className="fa fa-cogs" aria-hidden="true"></i>);
    }
}

export class ActiveFormIcon extends Component {
    render() {
        return (<i className="fa fa-file-o" aria-hidden="true"></i>);
    }
}

export class SubmittedFormIcon extends Component {
    render() {
        return (<i className="fa fa-file-text-o" aria-hidden="true"></i>);
    }
}

export class ProjectIcon extends Component {
    render() {
        return (<i className="fa fa-paint-brush" aria-hidden="true"></i>);
    }
}

export class SignOutIcon extends Component {
    render() {
        return (<i className="fa fa-sign-out" aria-hidden="true"></i>);
    }
}

export class RefreshIcon extends Component {
    render() {
        return (<i className="fa fa-refresh" aria-hidden="true"></i>);
    }
}

export class PlusIcon extends Component {
    render() {
        return (<i className="fa fa-plus" aria-hidden="true"></i>);
    }
}

export class CopyIcon extends Component {
    render() {
        return (<i className="fa fa-files-o" aria-hidden="true"></i>);
    }
}

export class RemoveIcon extends Component {
    render() {
        return (<i className="fa fa-trash-o" aria-hidden="true"></i>);
    }
}

export class UsersIcon extends Component {
    render() {
        return (<i className="fa fa-users" aria-hidden="true"></i>);
    }
}

export class UserIcon extends Component {
    render() {
        return (<i className="fa fa-user" aria-hidden="true"></i>);
    }
}

export class CheckboxCheckedIcon extends Component {
    render() {
        return (<i className="fa fa-check-square-o" aria-hidden="true"></i>);
    }
}

export class CheckboxUncheckedIcon extends Component {
    render() {
        return (<i className="fa fa-square-o" aria-hidden="true"></i>);
    }
}

export class PrintIcon extends Component {
    render() {
        return (<i className="fa fa-print" aria-hidden="true"></i>);
    }
}

export class DownloadIcon extends Component {
    render() {
        return (<i className="fa fa-download" aria-hidden="true"></i>);
    }
}