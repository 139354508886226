import {
    LOAD_USER
} from '../actionTypes';

export default function(user = {}, action) {

    switch(action.type) {
        case LOAD_USER:
            return Object.assign({}, action.user);
        default:
            return user;
    }

};