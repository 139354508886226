import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './components/App';
import configureStore from './store';
import actions from './actions/actions._';

const store = configureStore();
if (document.cookie && document.cookie.indexOf('token') >= 0) {
    store.dispatch(actions.user.getUser(true));
}

ReactDOM.render((<Provider store={store}><App/></Provider>), document.getElementById('app'));