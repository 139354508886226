import React, { Component, PropTypes } from 'react';
import styled from 'styled-components';
import Colors from './Colors';

export const Table = styled.table`
    border-collapse: collapse;
    border-bottom: 2px solid ${Colors.primary.alpha(0.5)};
    margin-bottom: 10px;
    width: 100%;
    thead {
        background-color: ${Colors.primary.alpha(0.5)};
        text-align: left;
        border-bottom: 2px solid ${Colors.primary};
    }
    th {
        text-transform: uppercase;
    }
    th, td {
        border: none;
        padding: 10px;
    }
    tbody tr:nth-child(even) {
        background-color: ${Colors.primary.alpha(0.1)}
    }
`;
