import React, { Component } from 'react';
import Overlay from '../reusable/Overlay';
import { FormGroup } from '../reusable/Form';
import { ButtonGroup, Button } from '../reusable/Button';

class ProjectCreateNew extends Component {

    setProperty(propertyName, event) {
        let updates = {};
        updates[propertyName] = event.target.value;
        this.props.actions.updateNewProject(updates);
    }

    clear() {
        this.props.actions.clearNewProject();
    }

    cancel() {
        this.props.actions.cancelNewProject();
    }

    add() {
        this.props.actions.addProject(this.props.newProject);
    }

    render() {
        if (!this.props.newProject) return null;
        return (
            <Overlay title="Nytt projekt">
                <div>
                    <FormGroup>
                        <label>Projektnummer</label>
                        <input type="text" value={this.props.newProject.projectNumber} onChange={this.setProperty.bind(this, 'projectNumber')} />
                    </FormGroup>
                    <FormGroup>
                        <label>Kund</label>
                        <input type="text" value={this.props.newProject.client} onChange={this.setProperty.bind(this, 'client')} />
                    </FormGroup>
                    <FormGroup>
                        <label>Adress</label>
                        <input type="text" value={this.props.newProject.address} onChange={this.setProperty.bind(this, 'address')} />
                    </FormGroup>
                    <FormGroup>
                        <label>Projektledare (kund)</label>
                        <input type="text" value={this.props.newProject.clientProjectManager} onChange={this.setProperty.bind(this, 'clientProjectManager')} />
                    </FormGroup>
                    <FormGroup>
                        <label>Projektledare (målare)</label>
                        <input type="text" value={this.props.newProject.painterProjectManager} onChange={this.setProperty.bind(this, 'painterProjectManager')} />
                    </FormGroup>
                    <ButtonGroup>
                        <Button onClick={this.cancel.bind(this)} type="cancel">Avbryt</Button>
                        <Button onClick={this.clear.bind(this)} type="cancel">Rensa</Button>
                        <Button onClick={this.add.bind(this)}>Lägg till</Button>
                    </ButtonGroup>
                </div>
            </Overlay>);
    }

}

export default ProjectCreateNew;