import {
    OPEN_ACTIVE_SELF_INSPECTION,
    CLOSE_ACTIVE_SELF_INSPECTION,
    INIT_SUBMIT_CURRENT_SELF_INSPECTION,
    CANCEL_SUBMIT_CURRENT_SELF_INSPECTION,
    DRAW_SUBMIT_CURRENT_SELF_INSPECTION_SIGNATURE_LINE,
    CLEAR_SUBMIT_CURRENT_SELF_INSPECTION_SIGNATURE,
    TOGGLE_SELF_INSPECTION_SORTED_TRASH,
    TOGGLE_SELF_INSPECTION_VACUUMED,
    TOGGLE_SELF_INSPECTION_SCRAPED_WINDOWS,
    SET_SELF_INSPECTION_COMPANY,
    SET_SELF_INSPECTION_LOCATION,
    UPDATE_NEW_SELF_INSPECTION_NOTES
} from '../actionTypes';

export default function(current = null, action) {
    switch(action.type) {
        case OPEN_ACTIVE_SELF_INSPECTION:
            return action.data;
        case CLOSE_ACTIVE_SELF_INSPECTION:
            return null;
        case INIT_SUBMIT_CURRENT_SELF_INSPECTION:
            if (!current) return;
            return Object.assign({}, current,
                {
                    signOf: {
                        sortedTrash: false,
                        vacuumed: false,
                        scrapedWindows: false,
                        name: action.name,
                        company: action.company,
                        location: 'Stockholm',
                        signature: {
                            width: 400,
                            height: 200,
                            lines: []
                        }
                    }
                });
        case CANCEL_SUBMIT_CURRENT_SELF_INSPECTION:
            return Object.assign({}, current, { signOf: null });
        case TOGGLE_SELF_INSPECTION_SORTED_TRASH:
            if (!current || !current.signOf) return;
            return Object.assign({}, current, { signOf: Object.assign({}, current.signOf, { sortedTrash: !current.signOf.sortedTrash }) });
        case TOGGLE_SELF_INSPECTION_VACUUMED:
            if (!current || !current.signOf) return;
            return Object.assign({}, current, { signOf: Object.assign({}, current.signOf, { vacuumed: !current.signOf.vacuumed }) });
        case TOGGLE_SELF_INSPECTION_SCRAPED_WINDOWS:
            if (!current || !current.signOf) return;
            return Object.assign({}, current, { signOf: Object.assign({}, current.signOf, { scrapedWindows: !current.signOf.scrapedWindows }) });
        case SET_SELF_INSPECTION_COMPANY:
            if (!current || !current.signOf) return;
            return Object.assign({}, current, { signOf: Object.assign({}, current.signOf, { company: action.value }) });
        case SET_SELF_INSPECTION_LOCATION:
            if (!current || !current.signOf) return;
            return Object.assign({}, current, { signOf: Object.assign({}, current.signOf, { location: action.value }) });
        case DRAW_SUBMIT_CURRENT_SELF_INSPECTION_SIGNATURE_LINE:
            if (!current || !current.signOf) return;
            return Object.assign({}, current, { signOf: Object.assign({}, current.signOf, { signature: Object.assign({}, current.signOf.signature, { lines: [ ...current.signOf.signature.lines, action.line ] }) }) });
        case CLEAR_SUBMIT_CURRENT_SELF_INSPECTION_SIGNATURE:
            if (!current || !current.signOf) return;
            return Object.assign({}, current, { signOf: Object.assign({}, current.signOf, { signature: Object.assign({}, current.signOf.signature, { lines: [] }) }) });
        case UPDATE_NEW_SELF_INSPECTION_NOTES:
            if (!current) return;
            return Object.assign({}, current, { notes: action.notes});
        default:
            return current;
    }
};