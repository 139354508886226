export default (query) => (obj) => {
    if (!query) return true;
    let match = true;
    const objString = JSON.stringify(obj).toLowerCase();
    query.toLowerCase().split(' ').forEach(w => {
        if (w && !objString.includes(w)) {
            match = false;
        }
    });
    return match;
}