import React, { Component, PropTypes } from 'react';
import styled from 'styled-components';
import Colors from '../reusable/Colors';

const defaultHeaderHeight = 80;

export const PageHeaderWrapper = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    height: ${props => props.height || defaultHeaderHeight}px;
    overflow: hidden;
    background-color: ${Colors.background.mix(Colors.primary, 0.4)};
    border-bottom: 4px solid ${Colors.primary};
    z-index: 100;
`;

export const PageHeaderContent = styled.div`
    max-width: 820px;
    margin: 0 auto;
    margin-left: 75px;
    h1 {
        margin: 0;
        float: left;
        margin-top: 20px;
    }
`;

export const Page = styled.div`
`;

export class PageHeader extends Component {

    render() {

        return (<PageHeaderWrapper height={this.props.height}>
            <PageHeaderContent>
                { this.props.children }
            </PageHeaderContent>
        </PageHeaderWrapper>);

    }

};

export const PageContent = styled.div`
    max-width: 820px;
    margin: 0 auto;
    margin-top: ${props => (props.headerHeight || defaultHeaderHeight) + 20}px  
`;