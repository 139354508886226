import apiClient from '../utils/apiClient';
import {
    SET_SIGN_IN_EMAIL,
    SET_SIGN_IN_PASSWORD,
    SIGN_IN,
    SIGN_OUT,
    LOAD_USER,
    LOAD_USERS,
    INIT_CREATE_NEW_USER,
    SET_NEW_USER_EMAIL,
    CANCEL_CREATE_NEW_USER
} from '../actionTypes';

const userActions = {

    setSignInEmail: (email) => {
        return {
            type: SET_SIGN_IN_EMAIL,
            email: email
        };
    },

    setSignInPassword: (password) => {
        return {
            type: SET_SIGN_IN_PASSWORD,
            password: password
        };
    },

    signIn: (email, password) => (dispatch) => {
        apiClient
            .post('/user/signIn', { email: email, password: password })
            .then((res) => {
                if (!res || !res.status === 200) return;
                dispatch({
                    type: SIGN_IN
                });
                dispatch({
                    type: LOAD_USER,
                    user: res.data
                });
            });
    },

    signOut: () => (dispatch) => {
        apiClient
            .post('/user/signOut', {})
            .then((res) => {
                if (!res || !res.status === 200) return;
                dispatch({
                    type: SIGN_OUT
                });
            });
    },

    getUser: (signIn) => (dispatch) => {
        apiClient
            .get('/user')
            .then((res) => {
                if (!res || !res.status === 200) return;
                dispatch({
                    type: LOAD_USER,
                    user: res.data
                });
                if (signIn) {
                    dispatch({
                        type: SIGN_IN
                    });
                }
            });
    },

    getUsers: () => (dispatch) => {
        apiClient
            .get('/user/users')
            .then((res) => {
                if (!res || !res.status === 200) return;
                dispatch({
                    type: LOAD_USERS,
                    users: res.data
                });
            });
    },

    deleteUser: (userId) => (dispatch) => {
        apiClient
            .delete(`/user/${userId}`)
            .then(() => userActions.getUsers()(dispatch));
    },

    updateUser: (userId, updates) => (dispatch) => {
        return apiClient
            .put(`/user/${userId}`, updates)
            .then((res) => {
                if (!res || !res.status === 200) {
                    throw new Error('Failed updating user');
                }
                userActions.getUsers()(dispatch);
            });
    },

    initCreateUser: () => {
        return {
            type: INIT_CREATE_NEW_USER
        }
    },

    cancelCreateUser: () => {
        return {
            type: CANCEL_CREATE_NEW_USER
        }
    },

    setNewUserEmail: (email) => {
        return {
            type: SET_NEW_USER_EMAIL,
            email: email
        }
    },

    createUser: (email) => (dispatch) => {
        apiClient
            .post('/user', { email: email })
            .then((res) => {
                if (!res || !res.status === 200) return;
                dispatch({
                    type: CANCEL_CREATE_NEW_USER
                });
                userActions.getUsers()(dispatch);
            });
    }

};

export default userActions;