import React, { Component, PropTypes } from 'react';
import  ReactDOM from 'react-dom';
import styled from 'styled-components';
import Colors from './Colors';

const SignatureSvg = styled.svg`
    border: 2px solid ${Colors.foreground};
    background-color: ${Colors.foreground.alpha(0.3)}
    margin-bottom: 10px;
    border-radius: 5px;
    line {
        stroke:${Colors.foreground};
        stroke-width:2;
    }
`;

const StyledCanvas = styled.canvas`
    border: 2px solid ${Colors.foreground};
    background-color: ${Colors.foreground.alpha(0.3)}
    margin-bottom: 10px;
    border-radius: 5px;
`;


const getOffset = (elem, getOffset) => {
    let offset = 0;
    do {
        if (!isNaN(getOffset(elem)))
        {
            offset += getOffset(elem);
        }
    } while(elem = elem.offsetParent);
    return offset;
};

const drawLine = (ctx, line) => {
    if (!line || line.length < 2) return;
    ctx.beginPath();
    ctx.moveTo(line[0].x, line[0].y);
    line.slice(1).forEach(point => {
        ctx.lineTo(point.x, point.y);
    });
    ctx.stroke();
};

class DrawableCanvas extends Component {

    constructor(props) {
        super(props);
        this.isDrawing = false;
        this.canvasPosition = { left:0, top:0 };
        this.line = [];
    }

    getCanvasCoordiantes(e, relocateSvg) {
        if (relocateSvg) {
            const canvas = ReactDOM.findDOMNode(this.canvas);
            this.canvasPosition.left = getOffset(canvas, c => c.offsetLeft);
            this.canvasPosition.top = getOffset(canvas, c => c.offsetTop);
        }
        return {
            x: (e.targetTouches ? e.targetTouches[0].clientX : e.clientX) - this.canvasPosition.left,
            y: (e.targetTouches ? e.targetTouches[0].clientY : e.clientY) - this.canvasPosition.top
        };
    }

    startDrawing(e) {
        this.isDrawing = true;
        document.ontouchmove = (event) => {
            event.preventDefault();
        };
        this.draw(e);
    }

    draw(e) {
        if (!this.isDrawing) return;
        const point = this.getCanvasCoordiantes(e, true);
        this.line.push(point);
        this.redraw();
    }

    stopDrawing() {
        if (!this.isDrawing) return;
        document.ontouchmove = () => {
            return true;
        };
        if (this.line && this.line.length > 1) {
            this.props.drawLine(this.line);
        }
        this.line = [];
        this.isDrawing = false;
    }

    redraw() {
        const context = ReactDOM.findDOMNode(this.canvas).getContext('2d');
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);
        context.strokeStyle = Colors.foreground;
        context.lineJoin = "round";
        context.lineWidth = 2;
        [...this.props.lines, this.line].forEach(line => drawLine(context, line));
    }

    componentDidMount() {
        this.redraw();
    }

    componentDidUpdate() {
        this.redraw();
    }

    render() {
        return (<div>
                <StyledCanvas
                    ref={canvas => { this.canvas = canvas; }}
                    width={this.props.width}
                    height={this.props.height}
                    onMouseDown = {this.startDrawing.bind(this)}
                    onTouchStart = {this.startDrawing.bind(this)}
                    onMouseMove = {this.draw.bind(this)}
                    onTouchMove = {this.draw.bind(this)}
                    onMouseUp = {this.stopDrawing.bind(this)}
                    onTouchEnd = {this.stopDrawing.bind(this)}
                    onMouseLeave= {this.stopDrawing.bind(this)}
                />
        </div>);
    }

}

export default DrawableCanvas;
