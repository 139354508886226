import { UPDATE_PROJECT_LIST_FILTER } from '../actionTypes';

export default function(listFilter = '', action) {
    switch(action.type) {
        case UPDATE_PROJECT_LIST_FILTER:
            return action.value;
        default:
            return listFilter;
    }
};
