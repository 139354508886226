import React, { Component } from 'react';
import Overlay from '../reusable/Overlay';
import { ButtonGroup, Button } from '../reusable/Button';
import { FormGroup } from '../reusable/Form';

class SelfInspectionCreateNew extends Component {

    updateRoomNumber(event) {
        this.props.updateRoomNumber(event.target.value);
    }

    create() {
        this.props.create(this.props.projectId, this.props.roomNumber);
    }

    cancel() {
        this.props.cancel();
    }

    render() {

        if (!this.props.userId || !this.props.projectId) return null;

        return (
            <Overlay title="Ny egenkontroll">
                <FormGroup>
                    <label>Projektnummer</label>
                    <input type="text" value={this.props.projectNumber} disabled="disabled" />
                </FormGroup>
                <FormGroup>
                    <label>Rum nummer</label>
                    <input type="text" onChange={this.updateRoomNumber.bind(this)} value={this.props.roomNumber} />
                </FormGroup>
                <ButtonGroup>
                    <Button onClick={this.cancel.bind(this)} type="cancel">Avbryt</Button>
                    { this.props.roomNumber ? <Button onClick={this.create.bind(this)}>Spara</Button> : null }
                </ButtonGroup>
            </Overlay>);

    }

}

export default SelfInspectionCreateNew;