import React, { Component } from 'react';
import { ButtonGroup, Button } from '../reusable/Button';
import { FormGroup } from '../reusable/Form';
import { Page, PageContent, PageHeader } from "../reusable/Page";
import { ActiveFormIcon } from '../reusable/Icons';
import SvgWithLines from '../reusable/SvgWithLines';
import { formatDate } from '../../utils/format';
import { Table } from '../reusable/Table';
import styled from 'styled-components';

const Tr = styled.tr`
    ${props => props.hasAction ? 'cursor: pointer;' : ''}
`;

class ControlPointRow extends Component {

    edit() {
        if (this.props.data.signature) return;
        this.props.actions.initNewControlPoint(this.props.formId, this.props.data.key, this.props.data.name);
    }

    render() {
        return (<Tr onClick={this.edit.bind(this)} hasAction={!this.props.data.signature}>
            <td>
                {this.props.data.name}
            </td>
            <td>{ (this.props.data.walls || []).join(', ') }</td>
            <td>{ this.props.data.remarksDescription }</td>
            <td>{ this.props.data.actions }</td>
            <td>{ this.props.data.responsible === 'M' ? 'Målare' : (this.props.data.responsible === 'B' ? 'Beställare' : '') }</td>
            <td>
                <SvgWithLines
                    width={this.props.data.signature ? this.props.data.signature.width : 60}
                    height={this.props.data.signature ? this.props.data.signature.height : 30}
                    displayWidth={60}
                    displayHeight={30}
                    lines={this.props.data.signature ? this.props.data.signature.lines: []}
                />
            </td>
            <td>{ formatDate(this.props.data.enteredAt) }</td>
        </Tr>);
    }

}

const getPropNameAndKey = (obj, key, name) => {
      return Object.assign({}, obj[key], { key: key, name: name});
};

class SelfInspectionActiveEdit extends Component {

    close() {
        this.props.close();
    }

    submit() {
        this.props.actions.initSubmit(`${this.props.user.firstName} ${this.props.user.lastName}`, 'Huddinge Måleriservice AB');
    }

    updateNotes(event) {
        if (this.notesDebounceTimer) {
            clearTimeout(this.notesDebounceTimer);
        }
        const notes = event.target.value;
        this.props.actions.updateNotes(notes);
        this.notesDebounceTimer = setTimeout(() => {
            this.notesDebounceTimer = null;
            this.props.actions.saveNotes(this.props.current.id, notes);
        }, 500);
    }

    render() {

        if (!this.props.current) return <div>Loading...</div>;

        const controlPoints = this.props.current.controlPoints || {};
        const hasControlPoint = Object.getOwnPropertyNames(controlPoints).length > 0;

        return (<Page>
            <PageHeader>
                <h1><ActiveFormIcon/> Project {this.props.current.projectNumber}, rum {this.props.current.roomNumber}</h1>
            </PageHeader>
            <PageContent>

                <ButtonGroup>
                    <Button onClick={this.close.bind(this)} type="cancel">Stäng</Button>
                    { !hasControlPoint ? null : <Button onClick={this.submit.bind(this)}>Skicka in</Button> }
                </ButtonGroup>

                <FormGroup>
                    <label>Projektnummer</label>
                    <input type="text" value={this.props.current.projectNumber} disabled="disabled"/>
                </FormGroup>
                <FormGroup>
                    <label>Rum nummer</label>
                    <input type="text" value={this.props.current.roomNumber} disabled="disabled"/>
                </FormGroup>
                <FormGroup>
                    <label>Adress</label>
                    <input type="text" value={this.props.current.address} disabled="disabled"/>
                </FormGroup>
                <FormGroup>
                    <label>Beställare</label>
                    <input type="text" value={this.props.current.client} disabled="disabled"/>
                </FormGroup>
                <FormGroup>
                    <label>Projektledare (målare)</label>
                    <input type="text" value={this.props.current.painterProjectManager} disabled="disabled"/>
                </FormGroup>
                <FormGroup>
                    <label>Projektledare (beställare)</label>
                    <input type="text" value={this.props.current.clientProjectManager} disabled="disabled"/>
                </FormGroup>
                <FormGroup>
                    <label>Kulörer/färger</label>
                    <input type="text" value={this.props.current.notes} onChange={this.updateNotes.bind(this)}/>
                </FormGroup>

                <Table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Vägg nr.</th>
                            <th>Synpunkter</th>
                            <th>Åtgärd</th>
                            <th>Ansvarig</th>
                            <th>Sign.</th>
                            <th>Datum</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ControlPointRow actions={this.props.actions} formId={this.props.current.id} data={getPropNameAndKey(controlPoints, 'vaggar', 'Väggar')}/>
                        <ControlPointRow actions={this.props.actions} formId={this.props.current.id} data={getPropNameAndKey(controlPoints, 'tak', 'Tak')}/>
                        <ControlPointRow actions={this.props.actions} formId={this.props.current.id} data={getPropNameAndKey(controlPoints, 'fonster', 'Fönster')}/>
                        <ControlPointRow actions={this.props.actions} formId={this.props.current.id} data={getPropNameAndKey(controlPoints, 'socklar', 'Socklar')}/>
                        <ControlPointRow actions={this.props.actions} formId={this.props.current.id} data={getPropNameAndKey(controlPoints, 'dorrar', 'Dörrar')}/>
                        <ControlPointRow actions={this.props.actions} formId={this.props.current.id} data={getPropNameAndKey(controlPoints, 'karmar', 'Karmar')}/>
                        <ControlPointRow actions={this.props.actions} formId={this.props.current.id} data={getPropNameAndKey(controlPoints, 'foder', 'Foder')}/>
                        <ControlPointRow actions={this.props.actions} formId={this.props.current.id} data={getPropNameAndKey(controlPoints, 'brostning', 'Bröstning')}/>
                        <ControlPointRow actions={this.props.actions} formId={this.props.current.id} data={getPropNameAndKey(controlPoints, 'omfattningFonster', 'Omfattning fönster')}/>
                        <ControlPointRow actions={this.props.actions} formId={this.props.current.id} data={getPropNameAndKey(controlPoints, 'element', 'Element')}/>
                        <ControlPointRow actions={this.props.actions} formId={this.props.current.id} data={getPropNameAndKey(controlPoints, 'fonsterbank', 'Fönsterbänk')}/>
                        <ControlPointRow actions={this.props.actions} formId={this.props.current.id} data={getPropNameAndKey(controlPoints, 'taklist', 'Taklist')}/>
                        <ControlPointRow actions={this.props.actions} formId={this.props.current.id} data={getPropNameAndKey(controlPoints, 'ror', 'Rör')}/>
                        <ControlPointRow actions={this.props.actions} formId={this.props.current.id} data={getPropNameAndKey(controlPoints, 'golv', 'Golv')}/>
                        <ControlPointRow actions={this.props.actions} formId={this.props.current.id} data={getPropNameAndKey(controlPoints, 'ovrigt', 'Övrigt')}/>
                    </tbody>
                </Table>

                <ButtonGroup left>
                    <Button onClick={this.close.bind(this)} type="cancel">Stäng</Button>
                    { !hasControlPoint ? null : <Button onClick={this.submit.bind(this)}>Skicka in</Button> }
                </ButtonGroup>

            </PageContent>
        </Page>);
    }

}

export default SelfInspectionActiveEdit;