import React, { Component } from 'react';
import Overlay from '../reusable/Overlay';
import { ButtonGroup, Button } from '../reusable/Button';
import DrawableSvg from '../reusable/DrawableCanvas';
import { FormGroup } from '../reusable/Form';


class SelfInspectionSubmit extends Component {

    cancel() {
        this.props.actions.cancelSubmit();
    }

    toggleScrapedWindows() {
        this.props.actions.toggleScrapedWindows();
    }

    toggleVacuumed() {
        this.props.actions.toggleVacuumed();
    }

    toggleSortedTrash() {
        this.props.actions.toggleSortedTrash();
    }

    updateLocation(event) {
        this.props.actions.updateSubmitLocation(event.target.value);
    }

    updateCompany(event) {
        this.props.actions.updateSubmitCompany(event.target.value);
    }

    clearSignature() {
        this.props.actions.clearSubmitSignature();
    }

    isComplete() {
        if (!this.props.current || !this.props.current.signOf) return false;
        if (!this.props.current.signOf.name || !this.props.current.signOf.name.trim() ) return false;
        if (!this.props.current.signOf.company || !this.props.current.signOf.company.trim() ) return false;
        if (!this.props.current.signOf.location || !this.props.current.signOf.location.trim() ) return false;
        if (!this.props.current.signOf.signature || this.props.current.signOf.signature.lines.length === 0) return false;
        return true;
    }

    submit() {
        this.props.actions.submit(this.props.current.id, this.props.current.signOf);
    }

    render() {

        if (!this.props.current || !this.props.current.signOf) return null;
        const signOf = this.props.current.signOf;


        return (
            <Overlay title="Här med intygas att lämnade uppgifter är korrekta">

                <p onClick={this.toggleScrapedWindows.bind(this)}><i className={`fa fa${signOf.scrapedWindows ? '-check' : ''}-square-o`} aria-hidden="true"></i> Skrapat fönster</p>
                <p onClick={this.toggleVacuumed.bind(this)}><i className={`fa fa${signOf.vacuumed ? '-check' : ''}-square-o`} aria-hidden="true"></i> Dammsugit</p>
                <p onClick={this.toggleSortedTrash.bind(this)}><i className={`fa fa${signOf.sortedTrash ? '-check' : ''}-square-o`} aria-hidden="true"></i> Sopor sorterade</p>

                <FormGroup>
                    <label>Namn</label>
                    <input type="text" value={signOf.name} disabled="disabled" />
                </FormGroup>
                <FormGroup>
                    <label>Företag</label>
                    <input type="text" value={signOf.company} onChange={this.updateCompany.bind(this)} />
                </FormGroup>
                <FormGroup>
                    <label>Ort</label>
                    <input type="text" value={signOf.location} onChange={this.updateLocation.bind(this)} />
                </FormGroup>

                <h3>Signatur</h3>
                <DrawableSvg
                    height={signOf.signature.height}
                    width={signOf.signature.width}
                    lines={signOf.signature.lines}
                    drawLine={this.props.actions.drawSubmitSignatureLine}
                />

                <ButtonGroup>
                    <Button onClick={this.clearSignature.bind(this)} type="cancel">Ångra signatur</Button>
                    <Button onClick={this.cancel.bind(this)} type="cancel">Avbryt</Button>
                    { !this.isComplete() ? null : <Button onClick={this.submit.bind(this)}>Godkänn och skicka in</Button> }
                </ButtonGroup>
            </Overlay>);

    }

}

export default SelfInspectionSubmit;