import React, { Component } from 'react';
import styled from 'styled-components';
import Overlay from '../reusable/Overlay';
import { ButtonGroup, Button } from '../reusable/Button';
import { FormGroup } from '../reusable/Form';
import DrawableSvg from '../reusable/DrawableCanvas';
import Colors from '../reusable/Colors';

const SelectedWall = styled.span`
        background-color: ${props => props.selected ? Colors.primary : Colors.primary.alpha(0.2)};
        margin: 5px;
        border: 2px solid ${props => props.selected ? Colors.primary : Colors.primary.alpha(0.4)};
        border-radius: 5px;
        padding: 5px 10px;
        cursor: pointer;
    `;

class WallSelector extends Component {

    toggle() {
        this.props.toggle(this.props.wallNr);
    }

    render() {
        return (<SelectedWall
            selected={this.props.selectedWalls.indexOf(this.props.wallNr) > -1}
            onClick={this.toggle.bind(this)}
        >
            {this.props.wallNr}
        </SelectedWall>);
    }

}

class SelfInspectionNewControlPoint extends Component {

    cancel() {
        this.props.actions.cancelNewControlPoint();
    }

    setRemark(value) {
        this.props.actions.setNewControlPointRemarks(value);
    }

    updateRemarksDescription(event) {
        this.props.actions.updateNewControlPointRemarksDescription(event.target.value);
    }

    updateActions(event) {
        this.props.actions.updateNewControlPointActions(event.target.value);
    }

    setResponsible(value) {
        this.props.actions.setNewControlPointResponsible(value);
    }

    clearSignature() {
        this.props.actions.clearNewControlPointSignature();
    }

    isComplete() {
        const cp = this.props.controlPoint;
        if (!cp || !cp.formId || !cp.key) return false;
        if (cp.remarks && !cp.remarksDescription) return false;
        if (['M', 'B'].indexOf(cp.responsible) < 0) return false;
        if (!cp.signature || cp.signature.lines.length === 0) return false;
        return true;
    }

    save() {
        this.props.actions.saveControlPoint(this.props.controlPoint);
    }

    render() {

        if (!this.props.controlPoint) return null;

        return (
            <Overlay title={this.props.controlPoint.name}>

                <h3>Vägg nummer</h3>
                <WallSelector wallNr={1} selectedWalls={this.props.controlPoint.walls} toggle={this.props.actions.toggleNewControlPointWall} />
                <WallSelector wallNr={2} selectedWalls={this.props.controlPoint.walls} toggle={this.props.actions.toggleNewControlPointWall} />
                <WallSelector wallNr={3} selectedWalls={this.props.controlPoint.walls} toggle={this.props.actions.toggleNewControlPointWall} />
                <WallSelector wallNr={4} selectedWalls={this.props.controlPoint.walls} toggle={this.props.actions.toggleNewControlPointWall} />
                <WallSelector wallNr={5} selectedWalls={this.props.controlPoint.walls} toggle={this.props.actions.toggleNewControlPointWall} />
                <WallSelector wallNr={6} selectedWalls={this.props.controlPoint.walls} toggle={this.props.actions.toggleNewControlPointWall} />

                <h3>Synpunkter</h3>
                <p onClick={this.setRemark.bind(this, false)}><i className={`fa fa${!this.props.controlPoint.remarks ? '-check' : ''}-square-o`} aria-hidden="true"></i> Inga anmärkningar</p>
                <p onClick={this.setRemark.bind(this, true)}><i className={`fa fa${this.props.controlPoint.remarks ? '-check' : ''}-square-o`} aria-hidden="true"></i> Anmärkning finns</p>

                { !this.props.controlPoint.remarks ? null : (<div>
                    <FormGroup>
                        <label>Anmärkning</label>
                        <input type="text"
                               onChange={this.updateRemarksDescription.bind(this)}
                               value={this.props.controlPoint.remarksDescription}
                               disabled={!this.props.controlPoint.remarks}/>
                    </FormGroup>
                    <FormGroup>
                        <label>Åtgärd</label>
                        <input type="text"
                               onChange={this.updateActions.bind(this)}
                               value={this.props.controlPoint.actions}
                               disabled={!this.props.controlPoint.remarks}/>
                    </FormGroup>
                </div>)}

                <h3>Ansvarig</h3>
                <p onClick={this.setResponsible.bind(this, 'M')}>
                    <i className={`fa fa${this.props.controlPoint.responsible === 'M' ? '-check' : ''}-square-o`} aria-hidden="true"></i> Målare
                </p>
                <p onClick={this.setResponsible.bind(this, 'B')}>
                    <i className={`fa fa${this.props.controlPoint.responsible === 'B' ? '-check' : ''}-square-o`} aria-hidden="true"></i> Beställare
                </p>

                <h3>Signatur</h3>
                <DrawableSvg
                    height={this.props.controlPoint.signature.height}
                    width={this.props.controlPoint.signature.width}
                    lines={this.props.controlPoint.signature.lines}
                    drawLine={this.props.actions.drawNewControlPointSignatureLine}
                />
                <ButtonGroup>
                    <Button onClick={this.clearSignature.bind(this)} type="cancel">Ångra signatur</Button>
                    <Button onClick={this.cancel.bind(this)} type="cancel">Avbryt</Button>
                    { !this.isComplete() ? null : <Button onClick={this.save.bind(this)}>Spara</Button> }
                </ButtonGroup>
            </Overlay>);

    }

}

export default SelfInspectionNewControlPoint;