import React, { Component } from 'react';
import ProjectCreateNew from './modals/ProjectCreateNew';
import SelfInspectionCreateNew from './modals/SelfInspectionCreateNew';
import SelfInspectionNewControlPoint from './modals/SelfInspectionNewControlPoint';
import SelfInspectionSubmit from './modals/SelfInspectionSubmit';
import UsersCreateNew from './modals/UsersCreateNew';
import AddEmailToProject from './modals/AddEmailToProject';

class Modals extends Component {

    render() {
        return (<div>
            <ProjectCreateNew
                actions={this.props.actions.project}
                newProject={this.props.project.newProject}
            />
            <SelfInspectionCreateNew
                userId={this.props.user.id}
                projectId={this.props.selfInspection.createNew.projectId}
                projectNumber={this.props.selfInspection.createNew.projectNumber}
                roomNumber={this.props.selfInspection.createNew.roomNumber}
                cancel={this.props.actions.selfInspection.cancelNew}
                updateRoomNumber={this.props.actions.selfInspection.updateNewRoomNumber}
                create={this.props.actions.selfInspection.createNew}
            />
            <SelfInspectionNewControlPoint
                controlPoint={this.props.selfInspection.newControlPoint}
                actions={this.props.actions.selfInspection}
            />
            <SelfInspectionSubmit
                current={this.props.selfInspection.currentActive}
                actions={this.props.actions.selfInspection}
            />
            <UsersCreateNew
                newUser={this.props.newUser}
                actions={this.props.actions.user}
            />
            <AddEmailToProject
                newEmail={this.props.project.newEmail}
                actions={this.props.actions.project}
            />
        </div>);
    }

}

export default Modals;