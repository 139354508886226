import apiClient from '../utils/apiClient';
import {
    RELOAD_ACTIVE_SELF_INSPECTION_LIST,
    RELOAD_SUBMITTED_SELF_INSPECTION_LIST,
    OPEN_ACTIVE_SELF_INSPECTION,
    CLOSE_ACTIVE_SELF_INSPECTION,
    INIT_NEW_SELF_INSPECTION,
    UPDATE_NEW_SELF_INSPECTION,
    UPDATE_NEW_SELF_INSPECTION_NOTES,
    CANCEL_NEW_SELF_INSPECTION,
    INIT_NEW_SELF_INSPECTION_CONTROL_POINT,
    CANCEL_SELF_INSPECTION_CONTROL_POINT,
    DRAW_SELF_INSPECTION_CONTROL_POINT_SIGNATURE_LINE,
    CLEAR_SELF_INSPECTION_CONTROL_POINT_SIGNATURE,
    TOGGLE_SELF_INSPECTION_CONTROL_POINT_WALL,
    SET_SELF_INSPECTION_CONTROL_POINT_REMARKS,
    SET_SELF_INSPECTION_CONTROL_POINT_REMARKS_DESCRIPTION,
    SET_SELF_INSPECTION_CONTROL_POINT_REMARKS_ACTIONS,
    SET_SELF_INSPECTION_CONTROL_POINT_RESPONSIBLE,
    INIT_SUBMIT_CURRENT_SELF_INSPECTION,
    CANCEL_SUBMIT_CURRENT_SELF_INSPECTION,
    TOGGLE_SELF_INSPECTION_SORTED_TRASH,
    TOGGLE_SELF_INSPECTION_VACUUMED,
    TOGGLE_SELF_INSPECTION_SCRAPED_WINDOWS,
    SET_SELF_INSPECTION_COMPANY,
    SET_SELF_INSPECTION_LOCATION,
    DRAW_SUBMIT_CURRENT_SELF_INSPECTION_SIGNATURE_LINE,
    CLEAR_SUBMIT_CURRENT_SELF_INSPECTION_SIGNATURE,
    OPEN_SUBMITTED_SELF_INSPECTION
} from '../actionTypes';
import navigationActions from './actions.navigation';

const loadActive = (dispatch) => {
    apiClient
        .get(`/self-inspection/active`)
        .then(res => {
            if (!res || !res.status === 200 || !res.data) return;
            dispatch({
                type: RELOAD_ACTIVE_SELF_INSPECTION_LIST,
                forms: res.data
            });
        });
};

const openActive = (id, dispatch) => {
    apiClient
        .get(`/self-inspection/active/${id}`)
        .then((res) => {
            if (!res || !res.status === 200 || !res.data || !res.data.id) return;
            dispatch({
                type: OPEN_ACTIVE_SELF_INSPECTION,
                data: res.data
            });
        });
};

const loadSubmitted = (dispatch) => {
    apiClient
        .get(`/self-inspection/submitted`)
        .then(res => {
            if (!res || !res.status === 200 || !res.data) return;
            dispatch({
                type: RELOAD_SUBMITTED_SELF_INSPECTION_LIST,
                forms: res.data
            });
        });
};

export default {

    loadActive: () => dispatch => {
        loadActive(dispatch);
    },

    loadSubmitted: () => dispatch => {
        loadSubmitted(dispatch);
    },

    openActive: id => dispatch => {
        openActive(id, dispatch);
    },

    deleteActive: (id) => dispatch => {
        apiClient
            .delete(`/self-inspection/active/${id}`)
            .then((res) => {
                dispatch({
                    type: CLOSE_ACTIVE_SELF_INSPECTION
                });
                loadActive(dispatch);
            });
    },

    initNew: (projectId, projectNumber) => {
        return {
            type: INIT_NEW_SELF_INSPECTION,
            projectId: projectId,
            projectNumber: projectNumber
        };
    },

    updateNewRoomNumber: roomNumber => {
        return {
            type: UPDATE_NEW_SELF_INSPECTION,
            roomNumber: roomNumber
        }
    },

    cancelNew: () => {
        return {
            type: CANCEL_NEW_SELF_INSPECTION
        };
    },

    createNew: (projectId, roomNumber) => dispatch => {
        apiClient
            .post('/self-inspection/active', {
                projectId: projectId,
                roomNumber: roomNumber
            })
            .then((res) => {
                if (!res || !res.status === 200 || !res.data || !res.data.id) return;
                dispatch({
                    type: CANCEL_NEW_SELF_INSPECTION
                });
                navigationActions.openActiveSelfInspection(res.data.id)(dispatch);
            });
    },

    initNewControlPoint: (formId, key, name) => {
        return {
            type: INIT_NEW_SELF_INSPECTION_CONTROL_POINT,
            formId: formId,
            key: key,
            name: name
        };
    },

    cancelNewControlPoint: () => {
        return {
            type: CANCEL_SELF_INSPECTION_CONTROL_POINT
        };
    },

    drawNewControlPointSignatureLine: (line) => {
        return {
            type: DRAW_SELF_INSPECTION_CONTROL_POINT_SIGNATURE_LINE,
            line: line
        };
    },

    clearNewControlPointSignature: () => {
        return {
            type: CLEAR_SELF_INSPECTION_CONTROL_POINT_SIGNATURE
        };
    },

    toggleNewControlPointWall: (wallNr) => {
        return {
            type: TOGGLE_SELF_INSPECTION_CONTROL_POINT_WALL,
            wallNr: wallNr
        };
    },

    setNewControlPointRemarks: (value)  => {
        return {
            type: SET_SELF_INSPECTION_CONTROL_POINT_REMARKS,
            value: value
        };
    },

    updateNewControlPointRemarksDescription: value => {
        return {
            type: SET_SELF_INSPECTION_CONTROL_POINT_REMARKS_DESCRIPTION,
            value: value
        };
    },

    updateNewControlPointActions: value => {
        return {
            type: SET_SELF_INSPECTION_CONTROL_POINT_REMARKS_ACTIONS,
            value: value
        };
    },

    setNewControlPointResponsible: value => {
        return {
            type: SET_SELF_INSPECTION_CONTROL_POINT_RESPONSIBLE,
            value: value
        };
    },

    saveControlPoint: (controlPoint) => dispatch => {
        apiClient
            .post(`/self-inspection/active/${controlPoint.formId}/control-point/${controlPoint.key}`, {
                walls: controlPoint.walls,
                remarks: controlPoint.remarks,
                remarksDescription: controlPoint.remarksDescription,
                actions:controlPoint.actions,
                responsible: controlPoint.responsible,
                signature: controlPoint.signature
            })
            .then((res) => {
                if (!res || !res.status === 200) return;
                dispatch({
                    type: CANCEL_SELF_INSPECTION_CONTROL_POINT
                });
                openActive(controlPoint.formId, dispatch);
            });
    },

    saveNotes: (id, notes) => dispatch => {
        apiClient
            .post(`/self-inspection/active/${id}/notes`, {
                notes: notes
            });
    },

    updateNotes: notes => {
        return {
            type: UPDATE_NEW_SELF_INSPECTION_NOTES,
            notes: notes
        };
    },

    closeCurrent: () => {
        return {
            type: CLOSE_ACTIVE_SELF_INSPECTION
        };
    },

    initSubmit: (name, company) => {
        return {
            type: INIT_SUBMIT_CURRENT_SELF_INSPECTION,
            name: name,
            company: company
        };
    },

    cancelSubmit: () => {
        return {
            type: CANCEL_SUBMIT_CURRENT_SELF_INSPECTION
        };
    },

    toggleSortedTrash: () => {
        return {
            type: TOGGLE_SELF_INSPECTION_SORTED_TRASH
        };
    },

    toggleVacuumed: () => {
        return {
            type: TOGGLE_SELF_INSPECTION_VACUUMED
        };
    },

    toggleScrapedWindows: () => {
        return {
            type: TOGGLE_SELF_INSPECTION_SCRAPED_WINDOWS
        };
    },

    updateSubmitLocation: (location) => {
        return {
            type: SET_SELF_INSPECTION_LOCATION,
            value: location
        };
    },

    updateSubmitCompany: (company) => {
        return {
            type: SET_SELF_INSPECTION_COMPANY,
            value: company
        };
    },

    drawSubmitSignatureLine: (line) => {
        return {
            type: DRAW_SUBMIT_CURRENT_SELF_INSPECTION_SIGNATURE_LINE,
            line: line
        };
    },

    clearSubmitSignature: () => {
        return {
            type: CLEAR_SUBMIT_CURRENT_SELF_INSPECTION_SIGNATURE
        };
    },

    submit: (id, signOf) => dispatch => {
        if (!id || !signOf) return;
        apiClient
            .post(`/self-inspection/active/${id}/submit`, signOf)
            .then((res) => {
                if (!res || !res.status === 200) return;
                dispatch({
                    type: CLOSE_ACTIVE_SELF_INSPECTION
                });
                navigationActions.openSubmittedSelfInspectionList()(dispatch);
            });
    },

    openSubmitted: id => {
        return {
            type: OPEN_SUBMITTED_SELF_INSPECTION,
            id: id
        };
    }

};