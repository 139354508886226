import apiClient from '../utils/apiClient';
import {
    APPEND_PENDING_USER_SETTINGS,
    CANCEL_PENDING_USER_SETTINGS,
    LOAD_USER
} from '../actionTypes';

const updateUser = (id, userUpdates) => {
    return apiClient
        .put('/user', userUpdates)
        .then((res) => {
            if (!res || !res.status === 200 || !res.data || !res.data.id) {
                throw new Error('Failed updating user');
            }
            return res.data;
        });
};

export default {
    addPendingUserUpdates: (userUpdates) => {
        return {
            type: APPEND_PENDING_USER_SETTINGS,
            userUpdates: userUpdates
        }
    },
    cancelPendingUpdates: () => {
        return {
            type: CANCEL_PENDING_USER_SETTINGS
        }
    },
    saveSettings: (id, settings) => (dispatch) => {
        updateUser(id, settings.userUpdates)
            .then((user) => {
                dispatch({
                    type: CANCEL_PENDING_USER_SETTINGS
                });
                dispatch({
                    type: LOAD_USER,
                    user: user
                });
            });
    }
};
