import React, { Component } from 'react';
import Overlay from '../reusable/Overlay';
import { FormGroup } from '../reusable/Form';
import { ButtonGroup, Button } from '../reusable/Button';

class AddEmailToProject extends Component {

    setEmail(event) {
        this.props.actions.setEmailListEmail(event.target.value);
    }

    cancel() {
        this.props.actions.cancelNewProjectEmail();
    }

    save() {
        this.props.actions.addEmail(this.props.newEmail.projectId, this.props.newEmail.email);
    }

    render() {
        if (!this.props.newEmail || !this.props.newEmail.projectId) return null;
        return (
            <Overlay title="Lägg till E-postadress">
                <div>
                    <FormGroup>
                        <label>E-postadress</label>
                        <input type="text" value={this.props.newEmail.email} onChange={this.setEmail.bind(this)} />
                    </FormGroup>
                    <ButtonGroup>
                        <Button onClick={this.cancel.bind(this)} type="cancel">Avbryt</Button>
                        <Button onClick={this.save.bind(this)}>Spara</Button>
                    </ButtonGroup>
                </div>
            </Overlay>);
    }

}

export default AddEmailToProject;