import { combineReducers } from 'redux';
import activeList from './reducer.selfInspection.activeList';
import currentActive from './reducer.selfInspection.currentActive';
import currentSubmitted from './reducer.selfInspection.currentSubmitted';
import allSubmitted from './reducer.selfInspection.submittedList';
import createNew from './reducer.selfInspection.newActive';
import newControlPoint from './reducer.selfInspection.newControlPoint';

export default combineReducers({
    activeList: activeList,
    currentActive: currentActive,
    currentSubmitted: currentSubmitted,
    allSubmitted: allSubmitted,
    createNew: createNew,
    newControlPoint: newControlPoint
});