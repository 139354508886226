import apiClient from '../utils/apiClient';
import {
    LOAD_PROJECT_LIST,
    UPDATE_PROJECT_LIST_FILTER,
    CANCEL_NEW_PROJECT,
    UPDATE_NEW_PROJECT,
    CLEAR_NEW_PROJECT,
    SET_PROJECT_EMAIL_LIST_EMAIL,
    SET_PROJECT_EMAIL_LIST_PROJECT_NUMBER,
    CANCEL_PROJECT_EMAIL_LIST_NEW_EMAIL
} from '../actionTypes';



const loadProjects = dispatch => {
    apiClient
        .get('/project')
        .then(res => {
            if (!res || !res.status === 200 || !res.data) return;
            dispatch({
                type: LOAD_PROJECT_LIST,
                projects: res.data
            });
        });
};

export default {
    loadProjects: () =>  loadProjects,
    setListFilter: value => {
        return {
            type: UPDATE_PROJECT_LIST_FILTER,
            value: value
        };
    },
    addProject: project => dispatch => {
        apiClient
            .post('/project', project)
            .then(() => {
                dispatch({
                    type: CANCEL_NEW_PROJECT
                });
            })
            .then(() => loadProjects(dispatch));
    },
    deleteProject: id => dispatch => {
        apiClient
            .delete(`/project/${id}`)
            .then(() => loadProjects(dispatch));
    },
    updateNewProject: updates => {
        return {
                type: UPDATE_NEW_PROJECT,
                updates: updates
            };
    },
    cancelNewProject: () => {
        return {
            type: CANCEL_NEW_PROJECT
        };
    },
    clearNewProject: () => {
        return {
            type: CLEAR_NEW_PROJECT
        };
    },
    setEmailListEmail: email => {
        return {
            type: SET_PROJECT_EMAIL_LIST_EMAIL,
            email: email
        };
    },
    initNewProjectEmail: projectId => {
        return {
            type: SET_PROJECT_EMAIL_LIST_PROJECT_NUMBER,
            projectId: projectId
        };
    },
    cancelNewProjectEmail: () => {
        return {
            type: CANCEL_PROJECT_EMAIL_LIST_NEW_EMAIL
        };
    },
    addEmail: (id, email) => (dispatch) => {
        apiClient
            .post(`/project/${id}/emailList`, { email: email })
            .then(() => dispatch({
                type: CANCEL_PROJECT_EMAIL_LIST_NEW_EMAIL
            }))
            .then(() => loadProjects(dispatch));
    },
    removeEmail: (id, email) => (dispatch) => {
        apiClient
            .delete(`/project/${id}/emailList/${email}`)
            .then(() => dispatch({
                type: CANCEL_PROJECT_EMAIL_LIST_NEW_EMAIL
            }))
            .then(() => loadProjects(dispatch));
    }
};
