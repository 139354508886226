import React, { Component, PropTypes } from 'react';
import styled from 'styled-components';
import Colors from './Colors';

const typeToColor = type => {
    switch(type) {
        case 'danger':
            return { background: Colors.red, foreground: Colors.background};
        case 'cancel':
            return { background: Colors.grayButton, foreground: Colors.background };
        case 'disabled':
            return { background: Colors.grayButton.alpha(0.5), foreground: Colors.background.alpha(0.5) };
        default:
            return { background: Colors.primary, foreground: Colors.background };
    }
};

export const ButtonGroup = styled.div`
    ${props => props.right ? 'float:right;' : ''}
    button {
        margin-right: 5px;
    }
    button:last-child {
        margin-right: 0;
    }
    ${props => props['no-margin'] ? '' : 'margin-bottom: 10px;'}
`;

export const Button = styled.button`
    border-style: none;
    padding: 0 20px;
    color: ${props => typeToColor(props.type).foreground};
    background-color:  ${props => typeToColor(props.type).background};
    cursor: ${props => props.type === 'disabled' ? 'auto' : 'pointer'};
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 200;
    font-size: 12px;
    line-height: 38px;
    min-width: 100px;
`;


