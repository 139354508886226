import React, { Component, PropTypes } from 'react';
import styled from 'styled-components';
import Colors from './Colors';

export const FormGroup = styled.div`
    label {
        text-transform: uppercase;
        display: block;
        font-size: 14px;
        font-weight: 300;
        text-indent: 3px;
        line-height: 20px;
    }
    input[type="text"], input[type="password"] {
        background-color: ${Colors.foreground.alpha(0.2)};
        border: 2px solid ${Colors.foreground.alpha(0.7)};
        -webkit-text-fill-color: ${Colors.foreground};
        font-weight: 100;
        font-size: 14px;
        text-indent: 10px;
        height: 38px;
        border-radius: 5px;
        display: block;
        width: 100%;
        box-sizing: border-box;
    }
    input[type="text"]:focus, input[type="password"]:focus {
        outline-width: 0;
        background-color: ${Colors.foreground.alpha(0.4)};
        border: 2px solid ${Colors.foreground};
    }
    input[type="text"]:disabled, input[type="password"]:disabled {
        outline-width: 0;
        background-color: ${Colors.foreground.alpha(0.1)};
        border: 2px solid ${Colors.foreground.alpha(0.3)};
    }
    margin-bottom: 10px;
    width: ${props => props.width || 'auto'};
`;
