import {
    RELOAD_SUBMITTED_SELF_INSPECTION_LIST
} from '../actionTypes';

export default function(submittedList = [], action) {
    switch(action.type) {
        case RELOAD_SUBMITTED_SELF_INSPECTION_LIST:
            return action.forms;
        default:
            return submittedList;
    }
};