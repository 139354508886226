import {
    LOAD_USERS
} from '../actionTypes';

export default function(users = [], action) {

    switch(action.type) {
        case LOAD_USERS:
            return action.users;
        default:
            return users;
    }

};