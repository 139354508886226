import React, { Component } from 'react';
import {Page, PageContent, PageHeader} from "../reusable/Page";
import { Panel, PanelHeader, PanelContent } from '../reusable/Layout';
import { FormGroup } from '../reusable/Form';
import { ButtonGroup, Button } from '../reusable/Button';
import styled from 'styled-components';

const LoginBox = styled.div`
`;

class Login extends Component {

    setEmail(event) {
        this.props.actions.setSignInEmail(event.target.value);
    }

    setPassword(event) {
        this.props.actions.setSignInPassword(event.target.value);
    }

    signIn() {
        this.props.actions.signIn(this.props.data.email, this.props.data.password);
    }

    render() {
        return (<Page>
            <PageContent>
                <LoginBox>
                    <h1>Egenkontroll</h1>
                    <FormGroup>
                        <label>E-postadress</label>
                        <input type="text" value={this.props.data.email} onChange={this.setEmail.bind(this)}></input>
                    </FormGroup>
                    <FormGroup>
                        <label>Lösenord</label>
                        <input type="password" value={this.props.data.password} onChange={this.setPassword.bind(this)}></input>
                    </FormGroup>
                    <ButtonGroup>
                        <Button onClick={this.signIn.bind(this)}>Logga in</Button>
                    </ButtonGroup>
                </LoginBox>
            </PageContent>
        </Page>);
    }

}

export default Login;