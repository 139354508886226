import React, { Component, PropTypes } from 'react';
import styled from 'styled-components';
import Color from './Colors';

const OverlayContent = styled.div`
            position: relative;
            width: ${props => props.overlayWidth};
            background-color: ${Color.background};
            margin: 0 auto;
            padding: 15px;
            margin-top: 30px;
            border-radius: 5px;
            border: 2px solid ${Color.foreground.alpha(0.5)}
        `;

const OverlayHeader = styled.div`
            h2 { 
                margin-top: 0;
                text-align: center;
            }
        `;

const OverlayWrapper = styled.div`
            height: 100%;
            width: 100%;
            position: fixed;
            z-index: 1000;
            left: 0;
            top: 0;
            background-color: rgb(0,0,0);
            background-color: rgba(0,0,0, 0.9);
            overflow-x: hidden;
        `;

class Overlay extends Component {

    render() {

        return (<OverlayWrapper>
            <OverlayContent overlayWidth={this.props.overlayWidth || '80%'}>
                { !this.props.title ? null : (<OverlayHeader>
                    <h2>{this.props.title}</h2>
                </OverlayHeader>) }
                { this.props.children }
            </OverlayContent>
        </OverlayWrapper>);

    }

}

export default Overlay;