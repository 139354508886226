import {
    SET_SIGN_IN_EMAIL,
    SET_SIGN_IN_PASSWORD,
    SIGN_IN
} from '../actionTypes';

export default function(authentication = { email: '', password: '', signedIn: false }, action) {

    switch(action.type) {
        case SET_SIGN_IN_EMAIL:
            return Object.assign({}, authentication, { email: action.email });
        case SET_SIGN_IN_PASSWORD:
            return Object.assign({}, authentication, { password: action.password });
        case SIGN_IN:
            return { email: '', password: '', signedIn: true };
        default:
            return authentication;
    }

};