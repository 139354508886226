import { combineReducers } from 'redux';
import projectList from './reducer.project.projectList';
import newProject from './reducer.project.newProject';
import listFilter from './reducer.project.listFilter';
import newEmail from './reducer.project.newEmail';

export default combineReducers({
    projectList: projectList,
    newProject: newProject,
    listFilter: listFilter,
    newEmail: newEmail
});