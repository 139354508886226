import {
    LOAD_PAGE,
    TOGGLE_MENU
} from '../actionTypes';
import userActions from './actions.user';
import projectActions from './actions.project';
import selfInspectionActions from './actions.selfInspection';

export default {

    openSettings: () => dispatch => {
        userActions.getUser()(dispatch);
        dispatch({
            type: LOAD_PAGE,
            page: 'settings'
        });
    },

    openProjectList: () => dispatch => {
        projectActions.loadProjects()(dispatch);
        dispatch({
            type: LOAD_PAGE,
            page: 'project-list'
        });
    },

    openActiveSelfInspectionList: () => dispatch => {
        selfInspectionActions.loadActive()(dispatch);
        dispatch({
            type: LOAD_PAGE,
            page: 'self-inspection/active-list'
        });
    },

    openActiveSelfInspection: (id) => dispatch => {
        selfInspectionActions.openActive(id)(dispatch);
        dispatch({
            type: LOAD_PAGE,
            page: 'self-inspection/active'
        });
    },

    openSubmittedSelfInspectionList: () => dispatch => {
        selfInspectionActions.loadSubmitted()(dispatch);
        dispatch({
            type: LOAD_PAGE,
            page: 'self-inspection/submitted-list'
        });
    },

    openSubmittedSelfInspection: (id) => dispatch => {
        dispatch(selfInspectionActions.openSubmitted(id));
        dispatch({
            type: LOAD_PAGE,
            page: 'self-inspection/submitted'
        });
    },

    openUsers: () => dispatch => {
        userActions.getUsers()(dispatch);
        dispatch({
            type: LOAD_PAGE,
            page: 'users'
        });
    },

    toggleNavigation: (visible) => { return { type: TOGGLE_MENU, visible: visible }}

};