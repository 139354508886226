import React, { Component, PropTypes } from 'react';
import styled from 'styled-components';

export const Panel = styled.div`
`;

export const PanelHeader = styled.div`
    padding: 5px;
    h1, h2, h3 {
        margin: 0;
        padding: 0;
    }
`;

export const PanelContent = styled.div`
    background-color: #FFFFFF;
    padding: 5px;
    overflow: hidden;
`;
