
// Authentication
export const SET_SIGN_IN_EMAIL = 'SET_SIGN_IN_EMAIL';
export const SET_SIGN_IN_PASSWORD = 'SET_SIGN_IN_PASSWORD';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';

// Navigation
export const LOAD_PAGE = 'LOAD_PAGE';
export const TOGGLE_MENU = 'TOGGLE_MENU';

// User
export const LOAD_USER = 'LOAD_USER';
export const LOAD_USERS = 'LOAD_USERS';
export const INIT_CREATE_NEW_USER = 'INIT_NEW_USER';
export const SET_NEW_USER_EMAIL = 'SET_NEW_USER_EMAIL';
export const CANCEL_CREATE_NEW_USER = 'CANCEL_CREATE_NEW_USER';

// Settings
export const APPEND_PENDING_USER_SETTINGS = 'APPEND_PENDING_USER_SETTINGS';
export const CANCEL_PENDING_USER_SETTINGS = 'CANCEL_PENDING_USER_SETTINGS';

// Self inspection
export const RELOAD_ACTIVE_SELF_INSPECTION_LIST = 'RELOAD_ACTIVE_SELF_INSPECTION_LIST';
export const RELOAD_SUBMITTED_SELF_INSPECTION_LIST = 'RELOAD_SUBMITTED_SELF_INSPECTION_LIST';
export const OPEN_ACTIVE_SELF_INSPECTION = 'OPEN_ACTIVE_SELF_INSPECTION';
export const CLOSE_ACTIVE_SELF_INSPECTION = 'CLOSE_ACTIVE_SELF_INSPECTION';
export const UPDATE_NEW_SELF_INSPECTION_NOTES = 'UPDATE_NEW_SELF_INSPECTION_NOTES';
export const INIT_SUBMIT_CURRENT_SELF_INSPECTION = 'INIT_SUBMIT_CURRENT_SELF_INSPECTION';
export const CANCEL_SUBMIT_CURRENT_SELF_INSPECTION = 'CANCEL_SUBMIT_CURRENT_SELF_INSPECTION';
export const TOGGLE_SELF_INSPECTION_SORTED_TRASH = 'TOGGLE_SELF_INSPECTION_SORTED_TRASH';
export const TOGGLE_SELF_INSPECTION_VACUUMED = 'TOGGLE_SELF_INSPECTION_VACUUMED';
export const TOGGLE_SELF_INSPECTION_SCRAPED_WINDOWS = 'TOGGLE_SELF_INSPECTION_SCRAPED_WINDOWS';
export const SET_SELF_INSPECTION_COMPANY = 'SET_SELF_INSPECTION_COMPANY';
export const SET_SELF_INSPECTION_LOCATION = 'SET_SELF_INSPECTION_LOCATION';
export const DRAW_SUBMIT_CURRENT_SELF_INSPECTION_SIGNATURE_LINE = 'DRAW_SUBMIT_CURRENT_SELF_INSPECTION_SIGNATURE_LINE';
export const CLEAR_SUBMIT_CURRENT_SELF_INSPECTION_SIGNATURE = 'CLEAR_SUBMIT_CURRENT_SELF_INSPECTION_SIGNATURE';
export const INIT_NEW_SELF_INSPECTION = 'INIT_NEW_SELF_INSPECTION';
export const CANCEL_NEW_SELF_INSPECTION = 'CANCEL_NEW_SELF_INSPECTION';
export const UPDATE_NEW_SELF_INSPECTION = 'UPDATE_NEW_SELF_INSPECTION';
export const INIT_NEW_SELF_INSPECTION_CONTROL_POINT = 'INIT_NEW_SELF_INSPECTION_CONTROL_POINT';
export const CANCEL_SELF_INSPECTION_CONTROL_POINT = 'CANCEL_SELF_INSPECTION_CONTROL_POINT';
export const DRAW_SELF_INSPECTION_CONTROL_POINT_SIGNATURE_LINE = 'DRAW_SELF_INSPECTION_CONTROL_POINT_SIGNATURE_LINE';
export const CLEAR_SELF_INSPECTION_CONTROL_POINT_SIGNATURE = 'CLEAR_SELF_INSPECTION_CONTROL_POINT_SIGNATURE';
export const TOGGLE_SELF_INSPECTION_CONTROL_POINT_WALL = 'TOGGLE_SELF_INSPECTION_CONTROL_POINT_WALL';
export const SET_SELF_INSPECTION_CONTROL_POINT_REMARKS = 'SET_SELF_INSPECTION_CONTROL_POINT_REMARKS';
export const SET_SELF_INSPECTION_CONTROL_POINT_REMARKS_DESCRIPTION = 'SET_SELF_INSPECTION_CONTROL_POINT_REMARKS_DESCRIPTION';
export const SET_SELF_INSPECTION_CONTROL_POINT_REMARKS_ACTIONS = 'SET_SELF_INSPECTION_CONTROL_POINT_REMARKS_ACTIONS';
export const SET_SELF_INSPECTION_CONTROL_POINT_RESPONSIBLE = 'SET_SELF_INSPECTION_CONTROL_POINT_RESPONSIBLE';
export const OPEN_SUBMITTED_SELF_INSPECTION = 'OPEN_SUBMITTED_SELF_INSPECTION';

// Project
export const LOAD_PROJECT_LIST = 'LOAD_PROJECT_LIST';
export const UPDATE_PROJECT_LIST_FILTER = 'UPDATE_PROJECT_LIST_FILTER';
export const UPDATE_NEW_PROJECT = 'UPDATE_NEW_PROJECT';
export const CANCEL_NEW_PROJECT = 'CANCEL_NEW_PROJECT';
export const CLEAR_NEW_PROJECT = 'CLEAR_NEW_PROJECT';
export const SET_PROJECT_EMAIL_LIST_EMAIL = 'SET_PROJECT_EMAIL_LIST_EMAIL';
export const SET_PROJECT_EMAIL_LIST_PROJECT_NUMBER = 'SET_PROJECT_EMAIL_LIST_PROJECT_NUMBER';
export const CANCEL_PROJECT_EMAIL_LIST_NEW_EMAIL = 'CANCEL_PROJECT_EMAIL_LIST_NEW_EMAIL';
