import { UPDATE_NEW_PROJECT, CANCEL_NEW_PROJECT, CLEAR_NEW_PROJECT } from '../actionTypes';

export default function(newProject = null, action) {
    switch(action.type) {
        case UPDATE_NEW_PROJECT:
            return Object.assign({}, newProject, action.updates);
        case CANCEL_NEW_PROJECT:
            return null;
        case CLEAR_NEW_PROJECT:
            return {};
        default:
            return newProject;
    }
};