import user from './actions.user';
import project from './actions.project';
import settings from './actions.settings';
import navigation from './actions.navigation';
import selfInspection from './actions.selfInspection';

export default {
    user: user,
    navigation: navigation,
    settings: settings,
    project: project,
    selfInspection: selfInspection
};