import {
    SET_SIGN_IN_EMAIL
} from '../actionTypes';

export default function(login = { email: '' }, action) {

    switch(action.type) {
        case SET_SIGN_IN_EMAIL:
            return Object.assign({}, login, { email: action.email });
        default:
            return login;
    }

};