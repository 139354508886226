import React, { Component } from 'react';
import { Page, PageContent, PageHeader } from "../reusable/Page";
import { ButtonGroup, Button } from '../reusable/Button';
import { Table } from '../reusable/Table';
import { SubmittedFormIcon, RefreshIcon } from '../reusable/Icons';
import { formatDate } from '../../utils/format';

const formSort = (f1,f2) => {
    const n1 = ((f1.projectNumber === f2.projectNumber) ? f1.roomNumber : f1.projectNumber).toUpperCase();
    const n2 = ((f1.projectNumber === f2.projectNumber) ? f2.roomNumber : f2.projectNumber).toUpperCase();
    if (n1 < n2) {
        return -1;
    }
    if (n1 > n2) {
        return 1;
    }
    return 0;
};

class SelfInspectionSubmittedList extends Component {

    open(id) {
        this.props.open(id);
        //window.open(`/api/self-inspection/submitted/${id}/pdf`,'_blank');
    }

    refresh() {
        this.props.actions.loadSubmitted();
    }

    render() {
        return (<Page>
            <PageHeader>
                <h1><SubmittedFormIcon/> Inskickade egenkontroller</h1>
            </PageHeader>
            <PageContent>
                <ButtonGroup>
                    <Button onClick={this.refresh.bind(this)}><RefreshIcon/></Button>
                </ButtonGroup>
                <Table>
                    <thead>
                    <tr>
                        <th>Projectnummer</th>
                        <th>Rum</th>
                        <th>Av</th>
                        <th>Inskickad</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.props.allSubmitted.sort(formSort).map(form => <tr key={form.id}>
                        <td>{form.projectNumber}</td>
                        <td>{form.roomNumber}</td>
                        <td>{form.signOf.name}</td>
                        <td>{formatDate(form.submittedAt)}</td>
                        <td>
                            <ButtonGroup no-margin right>
                                <Button onClick={this.open.bind(this, form.id)}>Öppna</Button>
                            </ButtonGroup>
                        </td>
                    </tr>)}
                    </tbody>

                </Table>
            </PageContent>
        </Page>);
    }

}

export default SelfInspectionSubmittedList;