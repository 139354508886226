import {
    APPEND_PENDING_USER_SETTINGS,
    CANCEL_PENDING_USER_SETTINGS
} from '../actionTypes';

export default function(settings = { userUpdates: {} }, action) {

    switch(action.type) {
        case CANCEL_PENDING_USER_SETTINGS:
            return Object.assign({}, settings, { userUpdates: {} });
        case APPEND_PENDING_USER_SETTINGS:
            return Object.assign({}, settings, { userUpdates: Object.assign({}, settings.userUpdates, action.userUpdates) });
        default:
            return settings;
    }

};