import { SIGN_OUT } from '../actionTypes';
import { combineReducers } from 'redux';
import authentication from './reducer.authentication';
import user from './reducer.user';
import users from './reducer.users';
import newUser from './reducer.newUser';
import signIn from './reducer.signIn';
import navigation from './reducer.navigation';
import settings from './reducer.settings';
import project from './reducer.project._';
import selfInspection from './reducer.selfInspection._';

const internalReducer = combineReducers({
    authentication: authentication,
    user: user,
    users: users,
    newUser: newUser,
    signIn: signIn,
    navigation: navigation,
    settings: settings,
    project: project,
    selfInspection: selfInspection
});

export default (state, action) => {
    if (action.type === SIGN_OUT) {
        return internalReducer(undefined, {});
    }
    return internalReducer(state, action);
};