import {
    SET_PROJECT_EMAIL_LIST_EMAIL,
    SET_PROJECT_EMAIL_LIST_PROJECT_NUMBER,
    CANCEL_PROJECT_EMAIL_LIST_NEW_EMAIL
} from '../actionTypes';

export default function(newEmail = null, action) {
    switch(action.type) {
        case SET_PROJECT_EMAIL_LIST_EMAIL:
            return Object.assign({}, newEmail, { email: action.email });
        case SET_PROJECT_EMAIL_LIST_PROJECT_NUMBER:
            return Object.assign({}, newEmail, { projectId: action.projectId });
        case CANCEL_PROJECT_EMAIL_LIST_NEW_EMAIL:
            return null;
        default:
            return newEmail;
    }
};
