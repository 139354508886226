import React, { Component } from 'react';
import { Page, PageHeader, PageContent } from "../reusable/Page";
import { Table } from '../reusable/Table';
import { Button, ButtonGroup } from '../reusable/Button';
import { FormGroup } from '../reusable/Form';
import { UsersIcon, UserIcon, CheckboxCheckedIcon, CheckboxUncheckedIcon, RefreshIcon, PlusIcon } from '../reusable/Icons';

class Users extends Component {

    reload() {
        this.props.actions.getUsers();
    }

    initCreateNew() {
        this.props.actions.initCreateUser();
    }

    setNewUserEmail() {

    }

    createNew() {

    }

    remove(userId) {
        if (userId === this.props.user.id) return;
        this.props.actions.deleteUser(userId);
    }

    toggleAdmin(userId, admin) {
        if (userId === this.props.user.id) return;
        this.props.actions.updateUser(userId, { admin: admin });
    }

    render() {
        return (
        <Page>
            <PageHeader>
                <h1><UsersIcon/> Användare</h1>
            </PageHeader>
            <PageContent>
                <ButtonGroup>
                    <Button onClick={this.reload.bind(this)}><RefreshIcon/></Button>
                    <Button onClick={this.initCreateNew.bind(this)}><PlusIcon/></Button>
                </ButtonGroup>
                <Table>
                    <thead>
                    <tr>
                        <th></th>
                        <th>E-postadress</th>
                        <th>Namn</th>
                        <th>Admin</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.props.users.map(user => (<tr>
                        <td><UserIcon/></td>
                        <td>{ user.email }</td>
                        <td>{ user.firstName } { user.lastName }</td>
                        <td onClick={this.toggleAdmin.bind(this, user.id, !user.admin)} style={{ cursor: this.props.user.id === user.id ? 'auto' : 'pointer' }}>
                            { user.admin ? <CheckboxCheckedIcon/> : <CheckboxUncheckedIcon/> }
                        </td>
                        <td>
                            <ButtonGroup>
                                <Button type={this.props.user.id === user.id ? 'disabled' : 'danger'} onClick={this.remove.bind(this, user.id)}>Ta bort</Button>
                            </ButtonGroup>
                        </td>
                    </tr>)) }
                    </tbody>
                </Table>
            </PageContent>
        </Page>);
    }

}

export default Users;