import React, { Component } from 'react';
import styled from 'styled-components';
import { Page, PageHeader, PageContent } from "../reusable/Page";
import { ButtonGroup, Button } from '../reusable/Button';
import { FormGroup } from '../reusable/Form';
import { Table } from '../reusable/Table';
import { ProjectIcon, RefreshIcon, PlusIcon, CopyIcon, RemoveIcon, DownloadIcon } from '../reusable/Icons';
import Colors from '../reusable/Colors';
import searchFilter from '../../utils/searchFilter';
import apiUrl from '../../utils/apiUrl';

const projectNumberSort = (p1,p2) => {
    const n1 = p1.projectNumber.toUpperCase();
    const n2 = p2.projectNumber.toUpperCase();
    if (n1 < n2) {
        return -1;
    }
    if (n1 > n2) {
        return 1;
    }
    return 0;
};

const Project = styled.div`
    border-top: 2px solid ${Colors.grayButton};
    overflow: hidden;
    h2 {
        margin: 10px 0;
    }
`;

const ProjectMetaData = styled.div`
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    margin-bottom: 15px;
    * {
        color: ${Colors.foreground.alpha(0.4)};
    }
`;


const ListOfProjects = styled.div`

`;

class ProjectList extends Component {

    reload() {
        this.props.actions.loadProjects();
    }

    delete(id) {
        this.props.actions.deleteProject(id);
    }

    createNew() {
        this.props.actions.updateNewProject({});
    }

    copy(project) {
        this.props.actions.updateNewProject(Object.assign({}, project, { projectNumber: '' }));
    }

    createSelfInspection(projectId, projectNumber) {
        this.props.createSelfInspection(projectId, projectNumber);
    }

    downloadZip(projectId) {
        var file_path = apiUrl + '/project/' + projectId + '/self-inspection/zip/';
        var a = document.createElement('A');
        a.href = file_path;
        a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    addEmail(projectId) {
        this.props.actions.initNewProjectEmail(projectId);
    }

    removeEmail(projectId, email) {
        this.props.actions.removeEmail(projectId, email);
    }

    updateFilter(event) {
        this.props.actions.setListFilter(event.target.value);
    }

    render() {
        return (
        <Page>
            <PageHeader>
                <h1><ProjectIcon/> Projekt</h1>
            </PageHeader>
            <PageContent>
                <FormGroup>
                    <label>Sök</label>
                    <input type="text" value={this.props.project.listFilter} onChange={this.updateFilter.bind(this)}/>
                </FormGroup>
                <ButtonGroup>
                    <Button onClick={this.reload.bind(this)}><RefreshIcon/></Button>
                    { !this.props.user.admin ? null : (<Button onClick={this.createNew.bind(this)}><PlusIcon/></Button>) }
                </ButtonGroup>
                <ListOfProjects>
                    {this.props.project.projectList
                        .filter(searchFilter(this.props.project.listFilter))
                        .sort(projectNumberSort)
                        .map(project => { return (
                            <Project key={project.id}>
                                <h2><ProjectIcon/> {project.projectNumber}</h2>
                                <ProjectMetaData>
                                    Projektnummer: {project.projectNumber}<br/>
                                    Kund: {project.client}<br/>
                                    Adress: {project.address}<br/>
                                    Projektledare (kund): {project.clientProjectManager}<br/>
                                    Projektledare (målare): {project.painterProjectManager}
                                </ProjectMetaData>
                                { !project.emailList || project.emailList.length === 0 ? null : (<div>
                                    <h3>E-postadresser</h3>
                                    <Table>
                                        <tbody>
                                            { project.emailList.map(email => (<tr>
                                                <td>{ email }</td>
                                                { !this.props.user.admin ? null : (<td>
                                                    <Button onClick={this.removeEmail.bind(this, project.id, email)} type="danger"><RemoveIcon/> Ta bort</Button>
                                                </td>) }
                                            </tr>)) }
                                        </tbody>
                                    </Table>

                                </div>) }
                                <ButtonGroup>
                                    <Button onClick={this.createSelfInspection.bind(this, project.id, project.projectNumber)}><PlusIcon/> Ny egenkontroll</Button>
                                    { !this.props.user.admin ? null : (<Button onClick={this.downloadZip.bind(this, project.id)}><DownloadIcon/> Ladda ner alla</Button>) }
                                    { !this.props.user.admin ? null : (<Button onClick={this.addEmail.bind(this, project.id)}><PlusIcon/> Ny e-postadress</Button>) }
                                    { !this.props.user.admin ? null : (<Button onClick={this.copy.bind(this, project)}><CopyIcon/> Kopiera</Button>) }
                                    { !this.props.user.admin ? null : (<Button onClick={this.delete.bind(this, project.id)} type="danger"><RemoveIcon/> Ta bort</Button>) }
                                </ButtonGroup>
                            </Project>
                        )})}
                </ListOfProjects>
            </PageContent>
        </Page>);
    }

}

export default ProjectList;