import {
    INIT_CREATE_NEW_USER,
    SET_NEW_USER_EMAIL,
    CANCEL_CREATE_NEW_USER
} from '../actionTypes';

export default function(newUser = null, action) {

    switch(action.type) {
        case INIT_CREATE_NEW_USER:
            return { email: '' };
        case SET_NEW_USER_EMAIL:
            return { email: action.email };
        case CANCEL_CREATE_NEW_USER:
            return null;
        default:
            return newUser;
    }

};