import React, { Component, PropTypes } from 'react';
import styled from 'styled-components';
import Colors from '../reusable/Colors';

const StyledSvg = styled.svg`
    line {
        stroke: ${Colors.foreground};
        stroke-width: ${props => props.strokeWidth};
    }
`;

class SvgWithLines extends Component {

    render() {

        const scale = Math.min(this.props.displayHeight/this.props.height, this.props.displayWidth/this.props.width);
        const strokeWidth = Math.round(1 / scale);

        return (
        <StyledSvg viewBox={`0 0 ${this.props.width} ${this.props.height}`} width={this.props.displayWidth || this.props.width} height={this.props.displayHeight || this.props.height} strokeWidth={strokeWidth}>
            {
                this.props.lines
                    .map(line => line
                            .slice(0, line.length-1)
                            .map((point, i) => {
                                return {
                                    x1: point.x,
                                    y1: point.y,
                                    x2: line[i+1].x,
                                    y2: line[i+1].y,
                                };
                            }))
                    .reduce((acc, lines) => acc.concat(lines), [])
                    .map((line, i) => { return (<line key={i} x1={line.x1} y1={line.y1} x2={line.x2} y2={line.y2} />) }) }
        </StyledSvg>
        );
    }

}

export default SvgWithLines;
