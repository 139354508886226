import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import actions from '../actions/actions._';
import wrapActions from '../wrapActions';
import Login from './pages/Login';
import Modals from './Modals';
import Navigation from './Navigation';
import Router from './Router';
import Colors from './reusable/Colors';

const AppWrapper = styled.div`
    color: ${Colors.foreground}
`;

class App extends Component {

    componentWillMount() {
        document.body.style.backgroundColor = Colors.background;
    }

    render() {

        if (!this.props.authentication.signedIn) {
            return (<AppWrapper>
                <Login
                    actions={this.props.actions.user}
                    data={this.props.authentication}>
                </Login>
            </AppWrapper>);
        }

        return (
            <AppWrapper>
                <Modals {...this.props} />
                <Navigation
                    actions={this.props.actions.navigation}
                    signOut={this.props.actions.user.signOut}
                    navigation={this.props.navigation}
                    user={this.props.user}
                />
                <Router {...this.props} />
            </AppWrapper>
            );
    }

}

export default connect(
        state => state,
        dispatch => { return { actions: wrapActions(actions, dispatch) } }
    )(App);