import {
    INIT_NEW_SELF_INSPECTION_CONTROL_POINT,
    CANCEL_SELF_INSPECTION_CONTROL_POINT,
    DRAW_SELF_INSPECTION_CONTROL_POINT_SIGNATURE_LINE,
    CLEAR_SELF_INSPECTION_CONTROL_POINT_SIGNATURE,
    TOGGLE_SELF_INSPECTION_CONTROL_POINT_WALL,
    SET_SELF_INSPECTION_CONTROL_POINT_REMARKS,
    SET_SELF_INSPECTION_CONTROL_POINT_REMARKS_DESCRIPTION,
    SET_SELF_INSPECTION_CONTROL_POINT_REMARKS_ACTIONS,
    SET_SELF_INSPECTION_CONTROL_POINT_RESPONSIBLE
} from '../actionTypes';

export default function(controlPoint = null, action) {
    switch(action.type) {
        case INIT_NEW_SELF_INSPECTION_CONTROL_POINT:
            return {
                formId: action.formId,
                key: action.key,
                name: action.name,
                walls: [],
                remarks: false,
                remarksDescription: '',
                actions: '',
                responsible: null,
                signature: {
                    width: 400,
                    height: 200,
                    lines: []
                }
            };
        case CANCEL_SELF_INSPECTION_CONTROL_POINT:
            return null;
        case DRAW_SELF_INSPECTION_CONTROL_POINT_SIGNATURE_LINE:
            return Object.assign({}, controlPoint, { signature: Object.assign({}, controlPoint.signature, { lines: [ ...controlPoint.signature.lines, action.line ] }) });
        case CLEAR_SELF_INSPECTION_CONTROL_POINT_SIGNATURE:
            return Object.assign({}, controlPoint, { signature: Object.assign({}, controlPoint.signature, { lines: [] }) });
        case TOGGLE_SELF_INSPECTION_CONTROL_POINT_WALL:
            const walls = controlPoint.walls.indexOf(action.wallNr) >= 0 ? controlPoint.walls.filter(w => w !== action.wallNr) : [ ...controlPoint.walls, action.wallNr].sort();
            return Object.assign({}, controlPoint, { walls: walls });
        case SET_SELF_INSPECTION_CONTROL_POINT_REMARKS:
            if (controlPoint.remarks === action.value) return controlPoint;
            return Object.assign({}, controlPoint, { remarks: action.value, remarksDescription: '', actions: '' });
        case SET_SELF_INSPECTION_CONTROL_POINT_REMARKS_DESCRIPTION:
            return Object.assign({}, controlPoint, { remarksDescription: action.value.substring(0,28) });
        case SET_SELF_INSPECTION_CONTROL_POINT_REMARKS_ACTIONS:
            return Object.assign({}, controlPoint, { actions: action.value.substring(0,28) });
        case SET_SELF_INSPECTION_CONTROL_POINT_RESPONSIBLE:
            return Object.assign({}, controlPoint, { responsible: action.value });
        default:
            return controlPoint;
    }
};