import {
    OPEN_SUBMITTED_SELF_INSPECTION
} from '../actionTypes';

export default function(current = null, action) {
    switch(action.type) {
        case OPEN_SUBMITTED_SELF_INSPECTION:
            return Object.assign({}, current, { id: action.id });
        default:
            return current;
    }
};