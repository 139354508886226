import {
    INIT_NEW_SELF_INSPECTION,
    CANCEL_NEW_SELF_INSPECTION,
    UPDATE_NEW_SELF_INSPECTION
} from '../actionTypes';

export default function(newActive = {}, action) {
    switch(action.type) {
        case INIT_NEW_SELF_INSPECTION:
            return { 
                projectId: action.projectId, 
                projectNumber: action.projectNumber, 
                roomNumber: action.roomNumber 
            };
        case CANCEL_NEW_SELF_INSPECTION:
            return {};
        case UPDATE_NEW_SELF_INSPECTION:
            if (!newActive) return;
            return {
                projectId: action.projectId || newActive.projectId,
                projectNumber: action.projectNumber || newActive.projectNumber,
                roomNumber: action.roomNumber || newActive.roomNumber
            };
        default:
            return newActive;
    }
};